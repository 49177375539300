import { createStore } from "vuex";
import ApiService from "@/services/ApiService";

export default createStore({
  state: {
    favouriteCharacters:
      JSON.parse(localStorage.getItem("favouriteCharacters")) || [],
  },
  mutations: {
    ADD_FAVOURITE(state, characterData) {
      state.favouriteCharacters.push(characterData);
      localStorage.setItem(
        "favouriteCharacters",
        JSON.stringify(state.favouriteCharacters)
      );
    },
    REMOVE_FAVOURITE(state, id) {
      state.favouriteCharacters = state.favouriteCharacters.filter(
        (characterData) => {
          return characterData.id != id;
        }
      );
      localStorage.setItem(
        "favouriteCharacters",
        JSON.stringify(state.favouriteCharacters)
      );
    },
  },
  actions: {
    addFavourite({ commit }, id) {
      ApiService.getCharacterById(id).then((response) => {
        commit("ADD_FAVOURITE", response.data);
      });
    },
    removeFavourite({ commit }, id) {
      commit("REMOVE_FAVOURITE", id);
    },
  },
  modules: {},
  getters: {
    favouriteCharactersCount: (state) => {
      return state.favouriteCharacters.length;
    },
  },
});
