import { createRouter, createWebHistory } from "vue-router";
import Characters from "../views/Characters.vue";
import Favourites from "../views/Favourites.vue";
import CharacterDetails from "../views/CharacterDetails.vue";

const routes = [
  {
    path: "/",
    redirect: "/characters",
  },
  {
    path: "/characters",
    name: "Characters",
    component: Characters,
  },
  {
    path: "/favourites",
    name: "Favourites",
    component: Favourites,
  },
  {
    path: "/character/:id",
    name: "CharacterDetails",
    props: true,
    component: CharacterDetails,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
