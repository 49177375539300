<template>
  <div v-if="episode" class="characterDetails">
    <div class="characterDetails__info">
      <h2>{{ character.name }}</h2>
      <h5>{{ character.species }} - {{ character.status }}</h5>
      <h5>Last known location: {{ character.location.name }}</h5>
      <h5>First seen in: {{ episode }}</h5>
      <AddToFavButton v-bind:characterId="character.id" />
    </div>
    <div
      v-bind:style="{ backgroundImage: 'url(' + character.image + ')' }"
      class="characterDetails__image"
    ></div>
  </div>
</template>

<script>
import ApiService from "../services/ApiService";
import AddToFavButton from "@/components/common/AddToFavButton.vue";

export default {
  name: "CharacterDetails",
  components: {
    AddToFavButton,
  },
  props: ["id"],
  data() {
    return {
      character: null,
      episode: null,
    };
  },
  created() {
    ApiService.getCharacterById(this.id)
      .then((response) => {
        this.character = response.data;
        ApiService.getEpisodeData(response.data.episode[0])
          .then((resp) => {
            this.episode = resp.data.name;
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  },
};
</script>

<style scoped lang="scss">
.characterDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70vw;
  margin: 30px auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  &__info {
    margin-left: 20px;
    text-align: left;
  }

  &__image {
    height: 250px;
    width: 250px;
    background-size: cover;
  }
}
</style>
