<template>
  <button class="addToFavButton" @click.prevent="toggleFavourite">
    {{ isFavourite ? "REMOVE FROM FAVOURITES" : "ADD TO FAVOURITES" }}
  </button>
</template>

<script>
export default {
  name: "AddToFavButton",
  props: ["characterId"],
  data() {
    return {
      isFavourite: false,
    };
  },
  created() {
    this.isFavourite =
      this.$store.state.favouriteCharacters.filter(
        (character) => character.id === this.characterId
      ).length > 0;
  },
  methods: {
    toggleFavourite() {
      this.isFavourite
        ? this.$store.dispatch("removeFavourite", this.characterId)
        : this.$store.dispatch("addFavourite", this.characterId);
      this.isFavourite = !this.isFavourite;
    },
  },
};
</script>

<style lang="scss">
.addToFavButton {
  margin: auto;
  margin-bottom: 10px;
  padding: 5px;
  color: white;
  background: black;
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    color: black;
    background: white;
    border: 1px solid black;
  }
}
</style>
