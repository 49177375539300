<template>
  <div class="charactersFilters">
    <div class="charactersFilters__filters">
      <span
        class="speciesFilter"
        v-bind:class="{ active: currentFilter === 'all' }"
        v-on:click="setFilter('all')"
      >
        All
      </span>
      <span
        class="speciesFilter"
        v-bind:class="{ active: currentFilter === 'human' }"
        v-on:click="setFilter('human')"
      >
        Human
      </span>
      <span
        class="speciesFilter"
        v-bind:class="{ active: currentFilter === 'animal' }"
        v-on:click="setFilter('animal')"
      >
        Animal
      </span>
      <span
        class="speciesFilter"
        v-bind:class="{ active: currentFilter === 'alien' }"
        v-on:click="setFilter('alien')"
      >
        Alien
      </span>
    </div>

    <form
      target="_top"
      class="charactersFilters__searchBox"
      @submit.prevent="searchByName"
    >
      <input
        name="name"
        placeholder="Search by name..."
        type="text"
        class="searchInput"
      />
      <button id="search-button" type="submit" class="submitButton">
        Search
      </button>
    </form>
  </div>
  <div class="charactersContainer" v-if="characters">
    <div class="charactersGrid">
      <CharacterCard
        v-for="character in characters"
        :key="character.id"
        :character="character"
      />
    </div>
    <div class="charactersNavigation">
      <p>Page {{ page }} of {{ pagesNumber }}</p>
      <div class="charactersNavigation__pages">
        <button
          :disabled="!prevPage"
          v-on:click="goToPage(prevPage), page--"
          target="_top"
        >
          &Lt; Prev
        </button>
        <button
          :disabled="!nextPage"
          v-on:click="goToPage(nextPage), page++"
          target="_top"
        >
          Next &Gt;
        </button>
      </div>
    </div>
  </div>
  <div v-else class="errorMessage">oops... we can't find this character:(</div>
</template>

<script>
import CharacterCard from "@/components/CharacterCard.vue";
import ApiService from "../services/ApiService";

export default {
  name: "Characters",
  components: {
    CharacterCard,
  },
  data() {
    return {
      characters: null,
      prevPage: null,
      nextPage: null,
      page: 1,
      pagesNumber: null,
      currentFilter: "all",
    };
  },
  created() {
    ApiService.getAllCharacters()
      .then((response) => this.setData(response.data))
      .catch((error) => console.log(error));
  },
  methods: {
    goToPage(pageUrl) {
      ApiService.getPageData(pageUrl)
        .then((response) => this.updateData(response.data))
        .catch((error) => console.log(error));
    },

    setFilter(newFilter) {
      this.currentFilter = newFilter;
      ApiService.getCharactersBySpecies(newFilter)
        .then((response) => this.setData(response.data))
        .catch((error) => console.log(error));
    },

    searchByName(submitEvent) {
      let searchedName = submitEvent.target.elements.name.value;
      submitEvent.target.elements.name.value = "";
      ApiService.getCharactersByName(searchedName, this.currentFilter)
        .then((response) => this.setData(response.data))
        .catch((error) => {
          this.characters = null;
          console.log(error);
        });
    },

    setData(data) {
      this.characters = data.results;
      this.prevPage = data.info.prev;
      this.nextPage = data.info.next;
      this.page = 1;
      this.pagesNumber = data.info.pages;
    },

    updateData(data) {
      this.characters = data.results;
      this.prevPage = data.info.prev;
      this.nextPage = data.info.next;
    },
  },
};
</script>

<style lang="scss">
.charactersGrid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.charactersNavigation {
  display: flex;
  justify-content: flex-end;
  margin: 30px 20px;

  &__pages {
    margin-left: 20px;

    button {
      background: transparent;
      border: none;
      font-size: 15px;
      cursor: pointer;
    }
  }
}

.charactersFilters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #eeeeee;

  &__filters {
    display: inline-block;
    overflow: auto;
    margin: 30px;
    border: 1px solid black;
    border-radius: 8px;
    cursor: pointer;

    .speciesFilter {
      padding: 10px;
      border-right: 1px solid black;
      &:last-child {
        border: none;
      }

      &.active {
        background: black;
        color: white;
      }
    }
  }

  &__searchBox {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 200px;
    margin: 10px;
    margin-left: 30px;
    border: 1px solid black;
    border-radius: 5px;
    overflow: hidden;

    .searchInput {
      padding: 10px;
      border: none;
    }

    .submitButton {
      position: absolute;
      right: 0;
      padding: 10px;
      border: none;
      border-left: 1px solid black;
      background: black;
      color: white;
      cursor: pointer;
      &:hover {
        background: white;
        color: black;
      }
    }
  }
}

.errorMessage {
  margin-top: 50px;
  font-size: 30px;
}
</style>
