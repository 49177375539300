import axios from "axios";

const apiClient = axios.create({
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getAllCharacters() {
    return apiClient.get("https://rickandmortyapi.com/api/character");
  },

  getCharacterById(id) {
    return apiClient.get(`https://rickandmortyapi.com/api/character/${id}`);
  },

  getPageData(pageUrl) {
    return apiClient.get(pageUrl);
  },

  getCharactersBySpecies(species) {
    return species !== "all"
      ? apiClient.get(
          `https://rickandmortyapi.com/api/character/?species=${species}`
        )
      : apiClient.get("https://rickandmortyapi.com/api/character");
  },

  getCharactersByName(name, species) {
    return species !== "all"
      ? apiClient.get(
          `https://rickandmortyapi.com/api/character/?name=${name}&species=${species}`
        )
      : apiClient.get(
          `https://rickandmortyapi.com/api/character/?name=${name}`
        );
  },

  getEpisodeData(episodeUrl) {
    return apiClient.get(episodeUrl);
  },
};
