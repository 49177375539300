<template>
  <div class="charactersGrid" v-if="favouriteCharacters.length">
    <CharacterCard
      v-for="character in favouriteCharacters"
      :key="character.id"
      :character="character"
    />
  </div>
  <h1 v-else>no cards.</h1>
</template>

<script>
import CharacterCard from "@/components/CharacterCard.vue";

export default {
  name: "CharacterDetails",
  components: {
    CharacterCard,
  },
  props: ["id"],
  computed: {
    favouriteCharacters() {
      return this.$store.state.favouriteCharacters;
    },
  },
};
</script>

<style scoped lang="scss"></style>
