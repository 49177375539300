<template>
  <router-link :to="{ name: 'CharacterDetails', params: { id: character.id } }">
    <div class="characterCard">
      <div
        v-bind:style="{ backgroundImage: 'url(' + character.image + ')' }"
        class="characterCard__image"
      ></div>
      <div class="characterCard__information">
        <p class="characterCard__name">{{ character.name }}</p>
        <p>{{ character.species }} - {{ character.status }}</p>
        <AddToFavButton v-bind:characterId="character.id" />
      </div>
    </div>
  </router-link>
</template>

<script>
import AddToFavButton from "@/components/common/AddToFavButton.vue";

export default {
  name: "CharacterCard",
  components: {
    AddToFavButton,
  },
  props: {
    character: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.characterCard {
  width: 200px;
  height: 300px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  &__information {
    display: flex;
    flex-direction: column;
    height: 40%;
  }

  &__image {
    width: 100%;
    height: 60%;
    background-size: cover;
  }

  &__name {
    margin: 7px 0;
    font-weight: bold;
  }

  &__addButton {
    margin: auto;
    margin-bottom: 10px;
    padding: 5px;
    color: white;
    background: black;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    &:hover {
      color: black;
      background: white;
      border: 1px solid black;
    }
  }
}
</style>
