<template>
  <div id="nav">
    <div>
      <router-link to="/characters">Characters</router-link>
      <router-link to="/favourites">Favourites</router-link>
    </div>

    <p style="font-size: 20px; color: gray">
      <i class="fa fa-heart-o"></i>
      {{ favouriteCharactersCount }}
    </p>
  </div>
  <router-view />
</template>

<script>
export default {
  computed: {
    favouriteCharactersCount() {
      return this.$store.getters.favouriteCharactersCount;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
}

p {
  margin: 0;
}
a {
  color: black;
  text-decoration: none;
}
#nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  background: black;

  a {
    margin-right: 20px;
    font-weight: bold;
    color: lightgray;
    text-decoration: none;

    &.router-link-exact-active {
      color: white;
    }
  }
}
</style>
